import React from "react";
import $ from "jquery";
import found1 from "../assets/img/404.gif";


export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
        this.onLine = this.onLine.bind(this);
        this.offLine = this.offLine.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        if (error) {
            setTimeout(() => {
                window.location.href = "/"
            }, 8000);
        }
    }
    onLine = async () => {
        var element = document.getElementById("alert_online_offline");
        if (element.classList.replace("bg-danger", "bg-success")) {
            element.classList.replace("bg-danger", "bg-success")
        } else {
            element.classList.add("bg-danger");
        }
        if (document.getElementById("Networkmsg").replaceChildren("Back to Online ...")) {
            document.getElementById("Networkmsg").replaceChildren("Back to Online ...")
        }
        var alert = $(".alert_online_offline");
        alert.show();
        await setTimeout(() => {
            alert.slideUp();
            window.location.reload()
        }, 3000);
    }
    offLine = async () => {
        var element = document.getElementById("alert_online_offline");
        var alert = $(".alert_online_offline");
        if (element.classList.replace("bg-success", "bg-danger")) {
            element.classList.replace("bg-success", "bg-danger")
        } else {
            element.classList.add("bg-danger");
        }
        if (document.getElementById("Networkmsg").replaceChildren("You are currently offline...")) {
            document.getElementById("Networkmsg").replaceChildren("You are currently offline...")
        }
        alert.show();
        // await setTimeout(() => {
        //     alert.slideUp();
        // }, 3000);
    }
    componentDidMount() {
        window.addEventListener("online", this.onLine);
        window.addEventListener("offline", this.offLine);
    }
    render() {
        if (this.state.errorInfo) {
            return (
                <div>
                    <div className="d-flex justify-content-center" >
                        <img src={localStorage.getItem('fileBase64') ? localStorage.getItem('fileBase64') : found1} />
                    </div>
                    <div className="d-flex justify-content-center" >
                        <h1>Whoops!</h1>
                    </div>
                    <div className="d-flex justify-content-center" >
                        <h4>Something went wrong.</h4>
                    </div>
                    <div className="d-flex justify-content-center" >
                        <h5 style={{ color: "#07278f" }}>This Page Will Be Automatically Reload in 8 Seconds</h5>
                    </div>
                </div>
            );
        }
        return this.props.children
    }
}


