import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import ErrorBoundary from '../screens/pages/Error';
import SitePreLoader from '../components/Site/SitePreLoader'

const Homepage = React.lazy(() => import('../screens/pages/Homepage'))
const Header = React.lazy(() => import("../screens/Header/Header"))
const Aboutus = React.lazy(() => import('../screens/pages/Aboutus'))
const Sellyourcar = React.lazy(() => import("../screens/pages/sellyourcar"))
const Test1 = React.lazy(() => import("../screens/pages/test"))
const Footer = React.lazy(() => import("../screens/Footer/Footer"))
const Contactus = React.lazy(() => import("../screens/pages/Contactus"))
const Service = React.lazy(() => import("../screens/pages/Service"))
const SingleListCars = React.lazy(() => import("../screens/pages/SingleListCars"))
const JustSold = React.lazy(() => import("../screens/pages/JustSold"))
const UpComingCars = React.lazy(() => import("../screens/pages/UpComingCars"))
const SAPolicy = React.lazy(() => import("../screens/pages/policy"))
const Testimonial = React.lazy(() => import("../screens/pages/Testimonial"))
const BuyCarsLatest = React.lazy(() => import("../screens/pages/BuyCarsLatest"))
const Notfound = React.lazy(() => import("../screens/pages/Notfound"))



export default class AdminRoutes extends Component {


  render() {

    return (
      <ErrorBoundary>
        <Suspense fallback={<SitePreLoader />}>
          <Router>

            <Header />
            <Switch>

              <Route
                exact
                path={"/"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <Homepage {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/buy-used-cars"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <BuyCarsLatest {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/buy-used-cars/:name"}
                render={(props) => {
                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          <BuyCarsLatest {...props} />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/used-cars"}
                render={(props) => {
                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          <BuyCarsLatest {...props} />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/buycars"}
                render={(props) => {
                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          <BuyCarsLatest {...props} />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={`/buy-used-cars/:name/:id`}
                render={(props) => {
                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          <SingleListCars {...props} />
                        </div>
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={`/SingleListCars`}
                render={(props) => {
                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <SingleListCars {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/JustSold"}
                render={(props) => {
                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <JustSold {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/UpComingCars"}
                render={(props) => {
                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <UpComingCars {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/aboutus"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <Aboutus {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/Contactus"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <Contactus {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/Service"}
                render={(props) => {
                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <Service {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/sellyourcars"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <Sellyourcar {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/sell-your-car"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <Sellyourcar {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />

              <Route
                exact
                path={"/Testimonial"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <Testimonial {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />

              <Route
                exact
                path={"/manage"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      <div class="sidebar-menu-container" id="sidebar-menu-container">
                        <div class="sidebar-menu-push">
                          {/* <Header {...props} /> */}
                          <Test1 {...props} />
                          {/* <Footer {...props} /> */}
                        </div>
                        {/* <SideNav /> */}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Route
                exact
                path={"/Policy"}
                render={(props) => {

                  return (
                    <React.Fragment>
                      {/* <Header {...props} /> */}
                      <div className="app-body ">
                        <SAPolicy {...props} />
                      </div>
                      {/* <Footer {...props} /> */}
                    </React.Fragment>
                  );
                }}
              />
              <Route>
                <Notfound />
              </Route>
            </Switch>
            <Footer />
          </Router>
        </Suspense>
      </ErrorBoundary>

    )
  }
}