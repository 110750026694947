import React from "react";
import AdminRoutes from "./Routes/AdminRoutes";

function App() {
  // localStorage.setItem("swtnId", 246);
  return (
    <React.Fragment>
      <AdminRoutes />
    </React.Fragment>
  );
}

export default App;