
import React, { Component } from "react";
import './SitePreLoader.css'

class SitePreLoader extends Component {
    constructor(props) {
        super(props)
        {
            this.state = {
                Loader: true,
            }
        }
    }
    async componentWillReceiveProps(props) {
        if (props) {
            if (props && props.fullpageloading == false && props.imageLoaded == true) {
                await this.setState({ Loader: false })
                // console.log(props, "propsloader", window.location.pathname);
            }
        }
    }
    async componentDidMount() {
        try {

        } catch (error) {
            console.log(error);
        }
    }
    render() {
        let width = window.innerWidth
        let height = 0
        if (width > 1000) {
            height = window.innerHeight - 200
        }
        else if (width > 800 && width < 1000) {
            height = window.innerHeight - 700
        }
        else if (width > 500 && width < 800) {
            height = window.innerHeight - 300
        }
        else {
            height = window.innerHeight - 500
        }
        const { Loader } = this.state;

        return (
            <>
                {Loader ?
                    <div class="loader-div" >
                        <div class="lds-ellipsis">
                            <div style={{ background: "#fc4c0d" }}>
                            </div>
                            <div style={{ background: "#07278f" }}>
                            </div>
                            <div style={{ background: "#fc4c0d" }}>
                            </div>
                            <div style={{ background: "#fc4c0d" }}>
                            </div>

                        </div>
                        <p id="loaderpara"><i>LOADING...</i></p>
                    </div>
                    : ""
                }
            </>


        )
    }
}
export default SitePreLoader;

